@mixin noScrollBar() {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
}

@mixin if($args...) {
    @each $arg in $args {
        @if type-of($arg) == number {
            @for $i from 1 through $arg {
                &:nth-child(#{$i}):nth-last-child(#{$arg - $i + 1}) {
                    @content;
                }
            }
        }
    }
}

@mixin safariRadiusFix() {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
}

@mixin opacity($o) {
    filter: alpha(opacity=$o);
    -moz-opacity: $o;
    -khtml-opacity: $o;
    opacity: $o;
}

@mixin shadow($l, $r, $b, $s, $c) {
    -webkit-box-shadow: $l $r $b $s $c;
    -moz-box-shadow: $l $r $b $s $c;
    box-shadow: $l $r $b $s $c;
}
@mixin box-sizing($val) {
    -webkit-box-sizing: $val;
    -moz-box-sizing: $val;
    box-sizing: $val;
}

@mixin transition-property($prop) {
    -webkit-transition-property: $prop;
    -moz-transition-property: $prop;
    -ms-transition-property: $prop;
    -o-transition-property: $prop;
    transition-property: $prop;
}

@mixin transition-duration($dur) {
    -webkit-transition-duration: $dur;
    -moz-transition-duration: $dur;
    -ms-transition-duration: $dur;
    -o-transition-duration: $dur;
    transition-duration: $dur;
}

@mixin transition() {
    @include transition-property(all);
    @include transition-duration($dur);
}

@mixin transition-delay($del) {
    -webkit-transition-delay: $del;
    -moz-transition-delay: $del;
    -ms-transition-delay: $del;
    -o-transition-delay: $del;
    transition-delay: $del;
}

@mixin transition-timing-function($eas) {
    -webkit-transition-timing-function: $eas;
    -moz-transition-timing-function: $eas;
    -ms-transition-timing-function: $eas;
    -o-transition-timing-function: $eas;
    transition-timing-function: $eas;
}

@mixin transform($trans) {
    -webkit-transform: $trans;
    -moz-transform: $trans;
    -ms-transform: $trans;
    -o-transform: $trans;
    transform: $trans;
}

@mixin translateX($trans) {
    -webkit-transform: translateX($trans);
    -moz-transform: translateX($trans);
    -ms-transform: translateX($trans);
    -o-transform: translateX($trans);
    transform: translateX($trans);
}

@mixin translate($x, $y, $z: 0, $r: 0) {
    -webkit-transform: translate($x, $y);
    -moz-transform: translate($x, $y);
    -ms-transform: translate($x, $y);
    -o-transform: translate($x, $y);
    transform: translate($x, $y);
}

@mixin translateY($trans) {
    -webkit-transform: translateY($trans);
    -moz-transform: translateY($trans);
    -ms-transform: translateY($trans);
    -o-transform: translateY($trans);
    transform: translateY($trans);
}

@mixin transform-origin($trans) {
    -webkit-transform-origin: $trans;
    -moz-transform-origin: $trans;
    -ms-transform-origin: $trans;
    -o-transform-origin: $trans;
    transform-origin: $trans;
}

@mixin scale($s) {
    -webkit-transform: scale($s);
    -moz-transform: scale($s);
    -ms-transform: scale($s);
    -o-transform: scale($s);
    transform: scale($s);
}

@mixin background-image($bg) {
    background: $bg;
}

@mixin filter($filter) {
    -webkit-filter: $filter;
    -moz-filter: $filter;
    filter: $filter;
}

@mixin appearance($val) {
    -webkit-appearance: $val;
    -moz-appearance: $val;
    appearance: $val;
}

@mixin border-radius($val) {
    -webkit-border-radius: $val;
    -moz-border-radius: $val;
    border-radius: $val;
}

@mixin border-bottom-right-radius($val) {
    -webkit-border-bottom-right-radius: $val;
    -moz-border-radius-bottomright: $val;
    border-bottom-right-radius: $val;
}

@mixin border-bottom-left-radius($val) {
    -webkit-border-bottom-left-radius: $val;
    -moz-border-radius-bottomleft: $val;
    border-bottom-left-radius: $val;
}

@mixin border-top-right-radius($val) {
    -webkit-border-top-right-radius: $val;
    -moz-border-radius-topright: $val;
    border-top-right-radius: $val;
}

@mixin border-top-left-radius($val) {
    -webkit-border-top-left-radius: $val;
    -moz-border-radius-topleft: $val;
    border-top-left-radius: $val;
}

@mixin rotate($deg) {
    -webkit-transform: rotate($deg);
    -ms-transform: rotate($deg);
    transform: rotate($deg);
}

@mixin linearGradient($l, $r) {
    background: $l;
    background-image: linear-gradient(to right, $l, $r);
    background: -moz-linear-gradient(to right, $l, $r);
    background: -webkit-linear-gradient(to right, $l, $r);
    background: -o-linear-gradient(to right, $l, $r);
    background: -ms-linear-gradient(to right, $l, $r); /*For IE10*/
    background: linear-gradient(to right, $l, $r);
    filter: progid:DXImageTransform.Microsoft.gradient(GradientType=0,startColorstr=#{$l}, endColorstr=#{$r}); /*For IE7-8-9*/
}

@mixin animation($anim) {
    -webkit-animation: $anim;
    -ms-animation: $anim;
    -moz-animation: $anim;
    -o-animation: $anim;
    animation: $anim;
}

@mixin animation-delay($dur) {
    -webkit-animation-delay: $dur;
    -moz-animation-delay: $dur;
    -ms-animation-delay: $dur;
    -o-animation-delay: $dur;
    animation-delay: $dur;
}

@mixin placeholder {
    &::-webkit-input-placeholder {
        @content;
    }

    &:-ms-input-placeholder {
        @content;
    }

    &::placeholder {
        @content;
    }
}
