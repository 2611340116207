$black: #000000;
$gray: #828282;
$darkGray: #333333;
$lightGray: #f3f3f3;
$white: #ffffff;
$red: #c21b17;
$orange: #e9573d;

$baseFontSize: 14px;
$baseFont: Helvetica, Arial, Tahoma, sans-serif;

$fwNormal: normal;
$fwBold: bold;

$basePath: '~@/assets/images/';

$dur: 300ms;
$rad: 1rem;

$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
    (
        0: 0,
        1: $spacer,
        2: (
            $spacer * 2
        ),
        3: (
            $spacer * 3
        ),
        4: (
            $spacer * 4
        ),
        5: (
            $spacer * 5
        )
    ),
    $spacers
);
