@import 'abstracts/variables';
@import 'abstracts/mixins';
@import 'abstracts/grid';
@import 'base/reset';

// Bootstrap
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/bootstrap-grid';

html {
    margin: 0;
    padding: 0;
    font-size: 10px;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
    margin: 0;
    padding: 0;
    color: $black;
    font-size: $baseFontSize;
    font-family: $baseFont;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    text-size-adjust: none !important;
    -moz-text-size-adjust: none !important;
    -webkit-text-size-adjust: none !important;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    @include less-than(md) {
        font-size: 1.3rem;
    }
}

#app {
    max-width: 100%;
}

.text-orange {
    color: $orange;
}

.chat__docs {
    display: flex;
    flex-direction: column;
    // margin: 1.5rem 0 0;

    &:deep(> *) {
        margin-bottom: 0.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.fade-enter-active,
.fade-leave-active {
    @include transition();
}

.fade-enter-from,
.fade-leave-to {
    @include opacity(0);
}
