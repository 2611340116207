// ******** DEFAULT
blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
    margin: 0;
    padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: inherit;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
    display: block;
}

sup {
    vertical-align: baseline;
    position: relative;
    top: -1rem;
}

sub {
    vertical-align: baseline;
    position: relative;
    top: 1rem;
}

a {
    text-decoration: none;
    @include transition-property(all);
    @include transition-duration($dur);
}

* {
    @include box-sizing(border-box);

    &:focus {
        outline: none;
    }
}

img {
    max-width: 100%;
}
